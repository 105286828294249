<template>
  <div class="absolute bottom-0 left-0 w-full h-auto bg-gradient-to-b z-50 from-blue-700 to-blue-500" style="">
    <div class="flex justify-center lg:justify-between flex-col md:flex-row items-center lg:items-stretch">
      <div class="flex w-auto md:w-1/3  flex-col">
        <a href="https://vk.com/zodchiy72" class="flex flex-row w-64 items-center mt-[55px] lg:mt-[60px] mr-auto ml-auto">
          <img class="flex h-[13px] w-[21px]" src="../../assets/icons/VK.png">
          <p class="flex h-1/2 text-gray-50 text-opacity-60 items-center text-[16px] font-medium ml-3">Зодчий Вконтакте</p>
        </a>

        <div class="flex flex-row w-64 items-center mr-auto ml-auto mt-[55px] lg:mt-[60px]">
          <img class="flex h-[21px] w-[21px] " src="../../assets/icons/TimeIco.png">
          <p class="flex h-1/2 text-gray-50 text-opacity-60 items-center text-[16px] font-medium ml-3">Ежедневно с 7:00 до 22:00</p>
        </div>
        <!-- ! -->
        <div class="flex flex-row w-64 items-center mr-auto ml-auto mt-[60px] ">
          <img class="flex h-[24px] w-[21px] " src="../../assets/icons/geoIco.png">
          <p class="flex h-1/2 text-gray-50 text-opacity-60 items-center text-[16px] font-medium ml-3">Луначарского, 2, корп. 5</p>
        </div>
        <!-- ! -->
      </div>
      <div class="w-8 lg:w-0"></div>
      <img class="w-1/3 max-w-[215px] object-scale-down mt-[60px] mr-auto ml-auto hidden lg:flex" src="../../assets/footerlogo.png" alt="">

      <div class="flex w-auto md:w-1/3  flex-col mt-[60px] ">
        <div class="flex flex-row w-64 items-center mr-auto ml-auto">
          <img class="flex h-[21px] w-[21px]" src="../../assets/icons/PhoneIco.png">
          <p class="flex h-1/2 text-gray-50 text-opacity-60 items-center text-[16px] font-medium ml-3">28-37-22</p>
        </div>
        <div class="flex flex-row w-64 items-center mt-[55px] lg:mt-[60px] mr-auto ml-auto">
          <img class="flex h-[21px] w-[21px]" src="../../assets/icons/PhoneIco.png">
          <p class="flex h-1/2 text-gray-50 text-opacity-60 items-center text-[16px] font-medium ml-3">28-37-78</p>
        </div>
        <!-- ! -->
        <a href="https://invite.viber.com/?g2=AQBIbVg8JoaOTUkYhb%2F6BbYgXpsd5Ob5M3BlbwHEsBdWJg2X9czyPN1eKelTMeOo&lang=ru" class="flex flex-row w-64 items-center mt-[55px] lg:mt-[60px] mr-auto ml-auto">
          <img class="flex h-auto w-[21px]" src="../../assets/icons/PhoneIco.png">
          <p class="flex h-1/2 text-gray-50 text-opacity-60 items-center text-[16px] font-medium ml-3">Зодчий Viber</p>
        </a>
        <!-- ! -->
      </div>

    </div>
    <div class="w-[95%] mt-12 mb-2 mr-auto ml-auto border border-opacity-60 border-white"></div>
    <div class="text-gray-50 text-opacity-60 text-[15px] text-center h-10">© 2023-2024 Зодчий | Все права защищены.</div>
  </div>



</template>
