<template>
  <NavBar/>
  <div class="flex items-center justify-center h-screen ">
    <div class="w-full z-20 h-[75%] rounded-[15px]">
      <loader v-if="!loaded" class=""/>
        <div class="py-16" v-if="loaded">
            <div v-if="!disableAuthCabinet" class="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
                <div class="hidden lg:block lg:w-1/2 bg-cover"
                    style="background-image:url('https://zodchiy72.ru/strapi/uploads/7_H_3u_E_Yf_Ql0_c0de52f151.jpg')">
                </div>
                <div class="w-full p-8 lg:w-1/2">
                    <h2 class="text-2xl font-semibold text-gray-700 text-center montsr">Зодчий</h2>
                    <p class="text-xl text-gray-600 text-center montsr">Личный кабинет</p>
                    <div class="mt-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2 montsr">Имя</label>
                        <input class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="email" />
                    </div>
                    <div class="mt-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2 montsr">Логин</label>
                        <input class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="email" />
                    </div>
                    <div class="mt-4">
                        <div class="flex justify-between">
                            <label class="block text-gray-700 text-sm font-bold mb-2 montsr">Пароль</label>
                        </div>
                        <input class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="password" />
                    </div>
                    <div class="flex justify-center">
                        <div class="relative inline-flex mt-8  group">
                            <div
                                class="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt">
                            </div>
                            <RouterLink to="/"
                                class="relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-white transition-all duration-200 bg-blue-600/80 montsr rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                                role="button">Войти
                            </RouterLink>
                        </div>
                    </div>

                    <div class="flex mt-8 border-t border-gray-300"></div>
                    <a href="strapi/admin/auth/login" class="flex justify-center mt-4 montsr text-gray-500/80 hover:underline hover:text-blue-600/70 text-[13px]">Войти в админ панель</a>

                </div>
            </div>

          <div v-else class="flex flex-col bg-white pb-6 rounded-lg shadow-lg font-semibold overflow-hidden mx-auto max-w-sm lg:max-w-4xl h-96">
            <p class="text-center mx-auto my-auto">
              Личный кабинет находится в разработке
            </p>
            <img src="../../assets/stubInDev.png" class="lg:w-[45%] px-8 my-auto w-max-[45%] h-max-[70%]  mx-auto pointer-events-none">
            <router-link to="/" class="px-8 py-2 border border-blue-500 w-max-[40%] hover:bg-blue-700 hover:text-white duration-300 text-sm rounded-xl mx-auto">На главную</router-link>
          </div>
        </div>
    </div>
  </div>
  <div class="h-72 mt-[220px]"/>
  <Footer/>
</template>

<script>
  import NavBar from '../sharing/NavBar'
  import Footer from '../sharing/FooterBar'
  import axios from "axios";
  import loader from "@/components/sharing/SiteLoader.vue";
//   import MapYa from '../sharing/MapYa.vue'

  export default {
    data() {
      return {
        loaded: false
      }
    },

    async mounted() {
      const siteSettings = (await axios.get('https://zodchiy72.ru/strapi/api/site-config')).data.data.attributes
      this.disableAuthCabinet = siteSettings.disableCabinet
      this.loaded = true

    },

    components: {
      loader,
      NavBar,
      Footer,
    }
  }
</script>
