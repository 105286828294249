<template>
  <NavBar/>
  <div class=" text-gray-800 text-[32px] md:text-5xl font-bold mt-[100px] mb-[40px] text-center md:text-left montsr">Расписание</div>

<!--  BANNER SCHEDULE-->

  <div v-if="showBanner" class="border rounded-xl border-red-300 bg-red-50/10 flex flex-col justify-center p-4 w-full">
    <h1>
      Уважаемые посетители, по техническим причинам, просьба смотреть расписание занятий через страницу услуг
    </h1>
    <router-link to="services"
      class="mt-4 w-[50%] mx-auto py-1.5 text-center rounded-[12px] border-[2px] border-blue-500 font-bold montsr hover:bg-blue-700 hover:text-white hover:border-blue-700"
    >
      Перейти
    </router-link>
  </div>
  <!--  BANNER SCHEDULE-->

  <div class="flex flex-row gap-2 montsr mb-[32px] h-[23px] text-[10px] md:text-[14px] text-gray-500">
    <p class="" v-if="age === '' && trainer == ''"></p>
    <button
    @click="BackTab('age')"
    >{{age}}</button>
    <p v-if="space">></p>
    <button
    @click="BackTab('space')"
    >{{space}} </button>
    <p v-if="service">></p>
    <button
    @click="BackTab('service')"
    >{{ service }}</button>
    <p v-if="trainer && age != ''">></p>
    <p v-if="this.$route.query.mytrener != undefined">Расписание тренера:</p>
    <button
    :disabled="this.$route.query.mytrener != undefined"
    @click="BackTab('trainer')"
    >{{ trainer }}</button>
  </div>
  <div class=" text-gray-800 text-[18px] md:text-[28px] mb-[12px] font-bold text-center md:text-left montsr" v-if="age !== ''">{{tabName}}</div>
<!--  -->
<div v-if="spaces !== undefined && spaces !== []">
  <div v-for="nameAge in ages" :key="nameAge.id" >
    <div v-if="(nameAge.attributes.name == this.age || age == '')">
      <div class=" text-gray-800 text-[18px] md:text-[28px] mb-[12px] mt-[32px] font-bold text-center md:text-left montsr" v-if="age == '' && this.$route.query.mytrener == undefined">{{nameAge.attributes.name}}</div>
        <!-- для всего кроме услуг и помещений -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-[8px]" v-if="this.space =='' && trainer != ' '">
          <div v-for="item in itemReturn(nameAge.attributes.name)" :key="item.id" >
            <button
              class="w-full py-2 px-4 text-center rounded-[12px] border-[2px] border-blue-500 font-bold montsr hover:bg-blue-700 hover:text-white hover:border-blue-700"
              @click="ChangeTab(item.attributes.name, nameAge.attributes.name)"
              :class="{ 'bg-blue-700 text-white border-blue-500': activeTab === item.attributes.name }">
              {{ item.attributes.name || item.attributes.uslugi.data.attributes.name }}
            </button>
          </div>
          <button
            class="w-full py-2 px-4 text-center rounded-[12px] border-[2px] border-blue-500 border-dashed font-bold montsr hover:bg-blue-600 hover:text-white hover:border-blue-600"
            @click="BackTab('back')" v-if="age != ''">
            Назад
          </button>
        </div>

        <!-- для услуг -->

        <div class="grid grid-cols-1 md:grid-cols-2 gap-[8px]" v-if="this.space !==''">
            <div v-for="item in itemReturn(nameAge.attributes.name)" :key="item.id">
              <button
                v-if="item != ' ' "
                class="w-full py-2 px-4  text-center rounded-[12px] border-[2px] border-blue-500 font-bold montsr hover:bg-blue-600 hover:text-white hover:border-blue-600"
                @click="ChangeTab(item, nameAge.attributes.name)"
                :class="{ 'bg-blue-500 text-white border-blue-500': activeTab === item }">
                {{ item }}
              </button>
            </div>
            <button
            class="w-full py-2 px-4 text-center rounded-[12px] border-[2px] border-blue-500 border-dashed font-bold montsr hover:bg-blue-700 hover:text-white hover:border-blue-700"
            @click="BackTab('back')" v-if="age != ''">
            Назад
          </button>
        </div>
      </div>
    </div>
</div>

<RouterLink v-if="serviceId" :to="'/service/' + this.serviceId" class="flex mt-[32px] px-2 py-3 w-full border-2 h-auto mx-auto justify-center hover:bg-blue-700 hover:border-blue-700 border-blue-700 rounded-[15px] hover:text-white text-bold text-opacity-80 font-bold text-[16px] montsr leading-[14px]">Записаться</RouterLink>
<button @click="ChangeRescheduling" class="flex mt-[16px] px-2 py-3 w-full border-2 border-dashed h-auto mx-auto justify-center hover:bg-blue-700 hover:border-blue-700 border-red-700 rounded-[15px] hover:text-white text-bold text-opacity-80 font-bold text-[16px] montsr leading-[14px]">Перенос занятий</button>

  <scheduleList
    class="mt-[72px]"
    :events = events
    v-if="events.length > 0 || services > servicesCount/2" />
    <SiteLoader v-if="ages.length <= 0 || spaces.length <= 0 || (this.service !=='' && events.length <= 0)" class="mx-auto mt-[100px]"/>

<!--  -->
  <!-- <div
  class='min-h-[300px]'
  :class="{'overflow-x-scroll': isMobile}"
  >
    <CalendarSchedule
    class="mt-[72px]"
    :class="{'min-w-[1600px]':isMobile}"
     v-if="events.length > 0 || services > servicesCount/2 && 1==0" :events = events />
  </div> -->


  <div class="flex h-[700px] md:h-[435px]"/><Footer/>

  <div v-for="item in reschedulingWindows" :key=item>
    <ReScheduling
      :show="showRescheduling"
      v-if="showRescheduling == true"
    />
  </div>

  <fsLoader class="z-50" v-if="loaded == false"/>

  <!-- <CalendarSchedule
    class="mt-[72px]"
    :class="{'min-w-[1600px]':isMobile}"
    :events = events /> -->

</template>


<script>
  import fsLoader from '../sharing/fullscreenLoader.vue'

  import axios from 'axios';
  import NavBar from '../sharing/NavBar'
  // import CalendarSchedule from './CalendarSchedule.vue'
  import SiteLoader from '../sharing/SiteLoader.vue'
  import ReScheduling from '../sharing/ReScheduling.vue'
  import Footer from '../sharing/FooterBar.vue'
  import scheduleList from './schedule/scheduleList.vue'

  export default {
    components: {
      fsLoader,
      ReScheduling,
      NavBar,
      // CalendarSchedule,
      SiteLoader,
      Footer,
      scheduleList,

  },
  data() {
    return {
      loaded: false,
      isMobile: false,

      reschedulingWindows: 0,
      showRescheduling: false,

      props: {
        test: {
          type: String,
          required: true,
        },
      },

      // обрабатываемые данные
      items: [],

      //не обработанные
      ages: [],
      spaces: [],
      spacesLen: 0,
      services: [],
      servicesNames: [],
      spacesNames: [],
      trainers: [],

      //выбранные
      activeTab: '',
      age: '',
      space: '',
      trainer: '',
      service: '',

      serviceId: '',

      tabName : 'Кто вы?',
      servicePageCount: 0,
      servicesCount: 0,
      events: [],
      weekDates: [],
    };
  },
  async mounted() {
    this.isMobile = window.innerWidth <= 768
    this.getWeekDates();

    const responseSpaces = await axios.get('https://zodchiy72.ru/strapi/api/space?pagination[pageSize]=50&pagination[page]=1&populate=kategorii_dlya_raspisaniyas')
    this.spaces = responseSpaces.data.data
    this.spacesLen = responseSpaces.data.meta.pagination.total
    this.items = this.spaces

    // получаем возраста
    const responseAge = await axios.get('https://zodchiy72.ru/strapi/api/age')
    this.ages = responseAge.data.data
    // this.items = this.ages


    var responseServices = await axios.get('https://zodchiy72.ru/strapi/api/schedule/?populate=ages&populate=trener&populate=uslugi&populate=pomeshheniya_dlya_raspisaniya&pagination[pageSize]=100&pagination[page]=0')
    this.services = responseServices.data.data

    this.servicesCount = responseServices.data.meta.pagination.total
    this.servicePageCount = responseServices.data.meta.pagination.pageCount

    for (let i = 2; i <= this.servicePageCount; i++) {
      responseServices = await axios.get('https://zodchiy72.ru/strapi/api/schedule/?populate=ages&populate=trener&populate=uslugi&populate=pomeshheniya_dlya_raspisaniya&pagination[pageSize]=100&pagination[page]=' + i)
      for (let j = 0; j < responseServices.data.data.length; j++) {
        this.services.push(responseServices.data.data[j])
      }
    }


    if(this.$route.query.myage !== undefined){
      this.age = this.$route.query.myage
      this.activeTab = this.age
    }
    if(this.$route.query.myspace !== undefined){
      this.space = this.$route.query.myspace
      this.activeTab = this.space
      this.getServicesName()
    }
    if(this.$route.query.myservice !== undefined){
      this.service = this.$route.query.myservice
      this.activeTab = this.service
      this.getTrainersNames()
      this.items = this.trainers
    }

    if(this.$route.query.mytrener !== undefined){
      this.trainer = this.$route.query.mytrener
      this.activeTab = this.trainer
      this.AddEventsForOneTrener()
      this.tabName = "Расписание тренера"
      this.items = []
    }

    const isMobileDevice = window.innerWidth <= 768;
    if (isMobileDevice) {
      this.activeView = 'day';
      this.hideTitleBar = false;
    }

    const siteSettings = (await axios.get('https://zodchiy72.ru/strapi/api/site-config')).data.data.attributes
    this.showBanner = siteSettings.bannerInSchedule

    this.loaded = true
  },
  // watch: {
  //   spaces: {
  //     immediate: true, // Запускать наблюдение сразу после создания компонента
  //     deep: true, // Глубокое наблюдение за изменениями объектов
  //     handler(newValue) {
  //       if (newValue) {
  //         // Выполнять функцию, только если есть значение в spaces
  //         this.itemReturn('desired_age'); // Замените 'desired_age' на фактическое значение
  //       }
  //     },
  //   },
  // },
  computed: {

  },
  methods: {

    ChangeRescheduling(){
      this.showRescheduling = true
      this.reschedulingWindows++
    },

    itemReturn: function(age) {
        var spacesNames = [];


        if (this.items == this.spaces) {
          //доходим
          for (let i = 0; i < this.spacesLen; i++) {
            for (let j = 0; j < this.spaces[i].attributes.kategorii_dlya_raspisaniyas.data.length; j++) {
              if (!spacesNames.includes(this.spaces[i].attributes.name) && this.spaces[i].attributes.kategorii_dlya_raspisaniyas.data[j].attributes.name == age) {
                spacesNames.push(this.spaces[i]);
              }
            }
          }
          return spacesNames;
        }
        else {
          return this.items;
        }
    },

    generateInitials(fullName) {
      const words = fullName.trim().split(' ');

      if (words.length >= 3) {

        return words[0] + ' ' + words[1][0] + '.' + ' ' + words[2][0] + '.';
      } else {
        return fullName;
      }
    },

    TimeConvert(day){
      if (day === "Понедельник") {
        return this.weekDates[0];
      } else if (day === "Вторник") {
        return this.weekDates[1];
      } else if (day === "Среда") {
        return this.weekDates[2];
      } else if (day === "Четверг") {
        return this.weekDates[3];
      } else if (day === "Пятница") {
        return this.weekDates[4];
      } else if (day === "Суббота") {
        return this.weekDates[5];
      } else if (day=== "Воскресенье") {
        return this.weekDates[6];
      }

    },
    ColorConvert(color) {
      const colorMap = {
        "Красный": "red",
        "Зелёный": "green",
        "Синий": "blue",
        "Фиолетовый": "purple",
        "Розовый": "pink",
        "Малиновый": "raspberry",
        "Белый": "white",
        "Серый": "gray",
        "Чёрный": "black",
        "Желтый": "yellow",
        "Оранжевый": "orange",
        "Бирюзовый": "turquoise",
        "Голубой": "cyan",
        "Лаймовый": "lime",
        "Оливковый": "olive",
        "Лавандовый": "lavender",
        "Индиго": "indigo",
        "Мятный": "mint",
        "Каштановый": "maroon",
        "Коралловый": "coral",
        "Золотой": "gold",
        "Серебряный": "silver",
        "Рубиновый": "ruby",
        "Изумрудный": "emerald",
        "Сапфировый": "sapphire",
        "Аметистовый": "amethyst",
        "Топазовый": "topaz",
        "Аквамариновый": "aquamarine",
        "Ониксовый": "onyx",
        "Жемчужный": "pearl",
        "Слоновая кость": "ivory",
        "Платиновый": "platinum",
      };

      return colorMap[color] || 'unknown'; // Return the class name or 'unknown' if color not found
    },

    BackTab(toTab){

      if(toTab == 'back'){
        if(this.trainer !=''){
          toTab = 'trainer'
        }else if(this.service != ''){
          toTab = 'service'
        }else if (this.space != ''){
          toTab = 'space'
        }else if (this.age != ''){
          toTab = 'age'
        }
      }

      if (toTab == 'age' || toTab == 'space'){
        this.servicesNames = []; this.trainers = []; this.spacesNames = []
        this.activeTab = ''; this.trainer = ''; this.service = ''; this.space = ''; this.age = ''; this.events = [];
        this.ChangeTab('')
      }else if (toTab == 'service'){
        this.activeTab = this.space; this.trainer = ''; this.service = ''; this.space = ''; this.events = [];
        this.ChangeTab(this.activeTab, this.age)
      }else if (toTab == 'trainer'){
        this.activeTab = this.service; this.trainer = ''; this.service = ''
        this.ChangeTab(this.activeTab, this.age)
      }
    },

    getServicesName(){
      this.tabName = 'Чем займёмся?'
      this.serviceId = ''

        //сортировка услуг и дубликатов
        for (let i = 0; i < this.services.length; i++) {
          this.AddEvents()
          if (!this.servicesNames.includes(this.services[i].attributes.uslugi.data.attributes.name)) {
            for (let j = 0; j < this.services[i].attributes.ages.data.length; j++) {
              if(this.services[i].attributes.ages.data[j].attributes.name == this.age && this.services[i].attributes.pomeshheniya_dlya_raspisaniya.data.attributes.name == this.space){ //проверка на соответствие категории (возраст) юзера
                this.servicesNames.push(this.services[i].attributes.uslugi.data.attributes.name)
              }
            }
          }
        }
        if(this.servicesNames.length === 0){
          this.tabName = 'Кажется, занятия в данном помещении, для вас отсутствуют'

        }
        this.items = this.servicesNames
    },

    async getTrainersNames(){
      this.trainers = []
      for (let i = 0; i < this.services.length; i++) {
        if (!this.trainers.includes(this.generateInitials(this.services[i].attributes.trener.data.attributes.Name)) && this.services[i].attributes.uslugi.data.attributes.name === this.service && this.services[i].attributes.pomeshheniya_dlya_raspisaniya.data.attributes.name == this.space) {
          this.trainers.push(this.generateInitials(this.services[i].attributes.trener.data.attributes.Name))
        }
      }

      this.AddEvents()

      var responseServices = await (await axios.get('https://zodchiy72.ru/strapi/api/catalogs?filters[name][$eq]=' + this.service))
      this.serviceId = responseServices.data.data[0].id

    },

    ChangeTab(tab, age){

      this.activeTab = tab

      if(this.activeTab === '')
      {
        this.items = this.spaces
      }
      else if(this.age === '' || this.space === '')
      {
        // this.age = tab
        this.age = age
        this.tabName = 'Куда пойдём?'
        this.items = this.spaces
        this.space = tab

        this.getServicesName()
      }else if(this.service === ''){
        this.service = tab
        this.tabName = 'Кто ваш тренер?'
        this.getTrainersNames()

        this.items = this.trainers
      }else if(this.trainer === ''){
        this.trainer = tab
        this.AddEvents()
      }else{
        this.trainer = tab
        this.AddEvents()
      }


    },


    AddEvents(){
      // this.events=[]
      this.events=[
        [], //пн
        [],
        [],
        [],
        [],
        [],
        [], //вс
      ]
      let dayNum = 0

      for (let i = 0; i < this.services.length; i++) {
        if(
          (this.generateInitials(this.services[i].attributes.trener.data.attributes.Name) == this.trainer || this.trainer == '')&&
          this.services[i].attributes.pomeshheniya_dlya_raspisaniya.data.attributes.name == this.space &&
          this.services[i].attributes.ages.data[0].attributes.name == this.age &&
          (this.services[i].attributes.uslugi.data.attributes.name === this.service || this.service == '' )
        ){ //проверка соответствия имени услуги и помещения
          dayNum = this.convertDayToNumber(this.services[i].attributes.day) - 1
          this.events[dayNum].push(
            {
              day: this.convertDayToNumber(this.services[i].attributes.day),
              start: this.services[i].attributes.StartTime.split(':').slice(0, 2).join(":"),
              end: this.services[i].attributes.EndTime.split(':').slice(0, 2).join(":"),
              title: this.generateInitials(this.services[i].attributes.trener.data.attributes.Name),
              content: this.services[i].attributes.uslugi.data.attributes.name,
              description: this.services[i].attributes.description,
              fitnesRoom: this.space == "Занятия в фитнес залах" ? true : false,
              class:  this.ColorConvert(this.services[i].attributes.Color),
            },
          );
        }
      }
      for (let i = 0; i < this.events.length; i++) {
        this.events[i].sort(this.compareTime);
      }
    },


    AddEventsForOneTrener(){
      // this.events=[]
      this.events=[
        [], //пн
        [],
        [],
        [],
        [],
        [],
        [], //вс
      ]
      let dayNum = 0

      for (let i = 0; i < this.services.length; i++) {
        if(this.services[i].attributes.trener.data.attributes.Name == this.trainer)

        { //проверка соответствия имени услуги и помещения
          dayNum = this.convertDayToNumber(this.services[i].attributes.day) - 1
          this.events[dayNum].push(
            {
              day: this.convertDayToNumber(this.services[i].attributes.day),
              start: this.services[i].attributes.StartTime.split(':').slice(0, 2).join(":"),
              end: this.services[i].attributes.EndTime.split(':').slice(0, 2).join(":"),
              title: this.generateInitials(this.services[i].attributes.trener.data.attributes.Name),
              content: this.services[i].attributes.uslugi.data.attributes.name,
              description: this.services[i].attributes.description,
              fitnesRoom: this.space == "Занятия в фитнес залах" ? true : false,
              class:  this.ColorConvert(this.services[i].attributes.Color),
            },
          );
        }
      }
    },

    compareTime(event1, event2) { //для сортировки по времени
        // Преобразуем строки времени в объекты Date
        var time1 = new Date('1970-01-01T' + event1.start + ':00');
        var time2 = new Date('1970-01-01T' + event2.start + ':00');

        // Сравниваем времена и возвращаем результат сравнения
        return time1 - time2;
    },

    getWeekDates() {
      const today = new Date();
      const dayOfWeek = today.getDay();

      let daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

      const monday = new Date(today);
      monday.setDate(today.getDate() - daysToSubtract);

      const weekDates = [];
      for (let i = 0; i < 7; i++) {
        const currentDate = new Date(monday);
        currentDate.setDate(monday.getDate() + i);
        weekDates.push(currentDate.toISOString().slice(0, 10));
      }

      this.weekDates = weekDates;
    },

    convertDayToNumber(day) {
      const daysOfWeek = [
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота",
        "Воскресенье"
      ];

      const index = daysOfWeek.indexOf(day);

      // Если день недели не найден, вернуть -1 или другое значение по вашему усмотрению
      // В данном случае, мы вернем -1
      return index !== -1 ? index + 1 : -1;
    },

  }
};
</script>
